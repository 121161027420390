/* eslint-disable @typescript-eslint/no-explicit-any */

import Workflow from './base';

class Individual extends Workflow {
  constructor() {
    super('individualwf');
    this.setDirectBaseUrl(
      'https://ri-d-usc-dom-indv-wf-aca.gentlebay-a25ffb7b.centralus.azurecontainerapps.io'
    );
  }

  async getDominoId(nfKey: string) {
    this.setAction('getDominoId');
    this.setData({
      idType: 'NFKey',
      value: nfKey,
    });
    this.setDiirectEndPoint(`/Individual/OtherIds/NFKey/${nfKey}`);
    const res: any = await this.getCall();

    return res?.data?.wfRes?.dominoId || res?.data?.dominoId || undefined;
  }

  async getDominoIndividual(dominoId: string) {
    this.setAction('getIndividual');
    this.setData({
      id: dominoId,
    });
    this.setDiirectEndPoint(`/Individual/${dominoId}`);
    const res = await this.getCall();

    return res?.data?.wfRes || res?.data || undefined;
  }

  async getContactInfo(dominoId: string) {
    this.setAction('getContactInfo');
    this.setData({
      dominoId,
    });
    this.setDiirectEndPoint(`/Individual/${dominoId}/ContactInfo`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async updateContactInfo(dominoId: string, data: any) {
    // this.setSwitch('direct');
    this.setAction('updateContactInfo');
    this.setData({
      dominoId,
      putData: data,
    });
    this.setDirectData(data);
    this.setDiirectEndPoint(`/Individual/${dominoId}/ContactInfo`);
    const res = await this.putCall();
    // this.setSwitch('app');
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getProfileInfo(dominoId: string) {
    this.setAction('getProfileInfo');
    this.setData({
      dominoId,
    });
    this.setDiirectEndPoint(`/Individual/${dominoId}/Profile`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async updateProfileInfo(dominoId: string, data: Record<string, any>) {
    this.setAction('updateProfileInfo');
    this.setData({
      dominoId,
      putData: data,
    });
    this.setDirectData(data);
    this.setDiirectEndPoint(`/Individual/${dominoId}/Profile`);
    const res = await this.putCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getProfessionalExperience(dominoId: string) {
    this.setAction('getProfessionalExperience');
    this.setData({
      dominoId,
    });
    this.setDiirectEndPoint(`/Individual/${dominoId}/Profession`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async updateProfessionalExperience(
    dominoId: string,
    sharingPermissionId: string,
    data: Record<string, any>
  ) {
    this.setAction('updateProfessionalExperience');
    this.setData({
      dominoId,
      sharingPermissionId,
      putData: data,
    });
    this.setDirectData(data);
    this.setDiirectEndPoint(
      `/Individual/${dominoId}/Profession?sharingPermissionId=${sharingPermissionId}`
    );
    const res = await this.putCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async updateEditBackground(data: Record<string, any>) {
    this.setAction('updateEditBackground');
    this.setData({
      putData: data,
    });
    this.setDirectData(data);
    this.setDiirectEndPoint(`/Individual/Aboutme`);
    const res = await this.putCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async getAreasofExpertise(dominoId: string) {
    this.setAction('getAreasOfExpertise');
    this.setData({
      dominoId,
    });
    this.setDiirectEndPoint(`/Individual/${dominoId}/ExpertiseAreas`);
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async updateAreasOfExpertise(
    dominoId: string,
    sharingPermissionId: string,
    data: Record<string, any>
  ) {
    this.setAction('updateAreasOfExpertise');
    this.setData({
      dominoId,
      sharingPermissionId,
      putData: data,
    });
    this.setDirectData(data);
    this.setDiirectEndPoint(
      `/Individual/${dominoId}/ExpertiseAreas?sharingPermissionId=${sharingPermissionId}`
    );
    const res = await this.putCall();
    return res?.data?.wfRes || res?.data || undefined;
  }
}

export default Individual;
