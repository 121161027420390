import React from 'react';

import TwoColumnGreyWrapperNPM from '@rotaryintl/component-two-column-grey-wrapper';
import { Placeholder, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const TwoColumnGreyWrapper = ({ rendering, params: { ComponentId } = {}, fields: { Title } = {} }) => (
  <TwoColumnGreyWrapperNPM
    id={ComponentId}
    title={<Text field={Title} />}
    col1Placeholder={<Placeholder name="2colgreywrapper-col1" rendering={rendering} />}
    col2Placeholder={<Placeholder name="2colgreywrapper-col2" rendering={rendering} />}
  />
);

export default withSitecoreContext()(TwoColumnGreyWrapper); 
