const tokenPattern = /\{{([^\}]*)\}}/g;

const findKeyValuePairs = function (obj, tokens) {
  if (!tokens) {
    return [];
  }
  let keyvaluepairs = [];
  for (const [key, value] of Object.entries(tokens)) {
    if (obj.includes(key)) {
      keyvaluepairs.push({ key: key, value: value });
    }
  }
  return keyvaluepairs;
};

export const replaceTokens = (value, tokens) => {
  if (value) {
    var matches = value.match(tokenPattern);
    if (matches) {
      let cleanedMatches = [];
      matches.map((t) => cleanedMatches.push(t.replace('{{', '').replace('}}', '')));
      const matchedTokens = findKeyValuePairs(cleanedMatches, tokens);
      if (matchedTokens) {
        matchedTokens.forEach((token, i) => {
          value = value.replace(`{{${token.key}}}`, token.value);
        });
      }
    }
  }
  return value; 
};