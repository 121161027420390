import { replaceTokens } from './token-helpers';

/** Create a link model from given field objects.
 * @param { { value: { href: string; target: string; } } } linkField The object for the link field.
 * @param { { value: string } } linkTextField The object for the single-line text field.
 * @param { { value: boolean } } externalCheckboxField The object for the checkbox field that toggles the 'external link' icon.
 * @param { any } linkData An anonymous object to attach to the link for additional purposes (event tracking, etc).
 * @returns {{ linkUrl: string; linkText?: string; linkTarget: '_self' | '_blank' | '_parent' | '_top'; external?: boolean; }}
 */
export const createLink = (linkField, linkTextField, externalCheckboxField, linkData = undefined) => ({
  linkUrl:
    linkField?.value?.href +
    (linkField?.value?.querystring ? `?${linkField?.value?.querystring}` : '') +
    (linkField?.value?.anchor ? `#${linkField?.value?.anchor}` : ''),
  linkText: linkTextField?.value,
  linkTarget: linkField?.value?.target,
  external: externalCheckboxField?.value,
  linkData,
});

/** Replace tokens in `linkUrl` and `linkText` of a provided link object.
 * @param link The link object returned by `createLink`
 * @param tokens The object returned by `MyRotary_GetTokens` in ./helper-functions.js
 */
export const tokenizeLink = (link, tokens) => ({
  ...link,
  linkUrl: replaceTokens(link.linkUrl, tokens),
  linkText: replaceTokens(link.linkText, tokens), 
});
/** Replace tokens in `linkUrl` and `linkText` of a provided link object.
 * @param text The text object returned by `createLink`
 * @param tokens The object returned by `MyRotary_GetTokens` in ./helper-functions.js
 */
export const tokenizeText = (text, tokens) => ({
  ...text,
  text: replaceTokens(text, tokens),
});

/** Replace the linkUrl of the provided link with `"/[link.linkData.languageCode]/[rest-of-current-window.location]"`.
 * @param link The link object returned by `createLink`
 * @example
 * createLanguageLink({ linkText: 'English', linkUrl: '/en', linkTarget: '_blank', linkData: { languageCode: 'en' }})
 * // => { linkText: 'English', linkUrl: '/en/path/to/current/page?q=test#anchor', linkTarget: '_blank', linkData: { languageCode: 'en' }} */
export const createLanguageLink = (link) => {
  if (typeof window === 'undefined') return link;

  if (link?.linkData?.languageCode === undefined) return link;

  // Split the pathname into slugs.
  // (window.location.pathname will always return at least one '/')
  // Ex:
  // "/" => ["", ""]
  // "/en-us" => ["", "en-us"]
  const paths = window.location.pathname.split('/');

  if (paths.length < 2) return link;

  // Swap the language slug with the given languageCode.
  // Ex:
  // ["", ""] => ["", "fr-fr"]
  // ["", "en-us"] => ["", "fr-fr"]
  paths[1] = link.linkData?.languageCode ?? paths[1];

  // Re-join the paths (+ querystring & hash)
  // Ex:
  // ["", "fr-fr"] => "/fr-fr?query=string#anchor"
  const linkUrl = paths.join('/') + window.location.search + window.location.hash;

  return {
    ...link,
    linkUrl,
  };
};