
import React, { useEffect, useState } from 'react';

import { LinkCard as ContentPageCardNPM } from '@rotaryintl/component-landing-pages';
import { Image, RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { MyRotary_getAllClubs, MyRotary_getAllDistrcits, MyRotary_getTokens } from '@domui-sitecore/utilities/helper-functions';
import { createLink, tokenizeLink, tokenizeText } from '@domui-sitecore/utilities/link-helpers';

const ContentPageCard = ({ sitecoreContext, fields: { CardLinkType, Title, Description, Link, Image: ImageField, IsFeatured } = {} }) => {
  const contextLanguage = sitecoreContext?.language.toLowerCase();
  const [tokens, setTokens] = useState({});
  useEffect(async () => {
    const tokensResponse = await MyRotary_getTokens(contextLanguage);
    setTokens(tokensResponse);
  }, []);

  const [allClubs, setAllClubs] = useState([]);
  useEffect(async () => {
    const response = await MyRotary_getAllClubs();
    setAllClubs(response);
  }, []);
  const [allDistricts, setAllDistricts] = useState([]);
  useEffect(async () => {
    const response = await MyRotary_getAllDistrcits();
    setAllDistricts(response);
  }, []);
  const linkType = CardLinkType?.fields?.Value?.value;

  const cardTitle = () => {
    if (linkType === 'club' || linkType === 'district') {
      return tokenizeText(Title?.value, tokens).text;
    } 
      return <Text field={Title} />;
    
  };

  if ((linkType === 'allclubs' && (!allClubs || allClubs.length <= 1)) || (linkType === 'alldistricts' && (!allDistricts || allDistricts.length <= 1))) {
    return <></>;
  } 
    return (
      <ContentPageCardNPM
        colSpan={IsFeatured?.value ? '2' : '1'}
        link={tokenizeLink(createLink(Link, Title), tokens)}
        title={cardTitle()}
        description={<RichText field={Description} />}
        image={ImageField?.value?.src && !ImageField?.value?.src.includes('default_image.svg') && <Image field={ImageField} />}
        backgroundColor="white"
      />
    );
  
};

export default withSitecoreContext()(ContentPageCard); 