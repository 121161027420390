export const SITECORE_INDIVIDUAL_TOKEN = 'SITECORE_INDIVIDUAL_TOKEN';
export const DOMUI_CLUB_DISTRICT_DATA = 'DOMUI_CLUB_DISTRICT_DATA';
export const SITECORE_PRODUCTION_LAYOUT_URL =
  'https://myrotary.content.rotary.org';
export const SITECORE_PROD_ROUTES_BASE_URL = 'https://myrotary.cms.rotary.org';
export const SECURED_PATHS = [
  'my-rotary',
  'participate',
  'events-and-connections',
  'knowledge-and-resources',
  'who-we-are',
];
