import React from 'react';

import { CardGroupSection3Col as CardGroupSection3ColNPM } from '@rotaryintl/component-landing-pages';
import { Placeholder, RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { createLink } from '@domui-sitecore/utilities/link-helpers';

const CardGroupSection3Col = ({ sitecoreContext, params: { ComponentId } = {}, rendering, fields: { Title, Description, Link, LinkText } = {} }) => {
  const isEditing = sitecoreContext?.pageEditing;
  return (
    <CardGroupSection3ColNPM
      id={ComponentId}
      title={(isEditing || Title?.value !== '') && <Text field={Title} />}
      description={(isEditing || Description?.value !== '') && <RichText field={Description} />}
      link={(isEditing || Link?.value?.href !== '') && createLink(Link, LinkText)}
      cardsPlaceholder={<Placeholder name="cardgroupsection3col-cards" rendering={rendering} />}
    />
  );
};

export default withSitecoreContext()(CardGroupSection3Col); 