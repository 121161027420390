import React from 'react';

import OneColumnCalloutPanelNPM from '@rotaryintl/component-one-column-callout-panel';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { createLink } from '@domui-sitecore/utilities/link-helpers';

const OneColumnCalloutPanel = ({
  params: { ComponentId } = {},
  fields: { Title, Description, BackgroundImageDesktop, BackgroundImageMobile, Link, LinkText, ShowExternalLinkIcon } = {},
}) => (
  <OneColumnCalloutPanelNPM
    id={ComponentId}
    title={<RichText field={Title} />}
    description={<RichText field={Description} />}
    backgroundImageDesktop={BackgroundImageDesktop?.value?.src}
    backgroundImageMobile={BackgroundImageMobile?.value?.src}
    link={createLink(Link, LinkText, ShowExternalLinkIcon)}
  />
);

export default withSitecoreContext()(OneColumnCalloutPanel); 
