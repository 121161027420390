/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

import directCall from './direct';

class Workflow {
  switch: 'app' | 'direct' = 'app';

  workflow!: string;

  action!: string;

  directBaseUrl!: string;

  directEndPoint!: string;

  baseUrl = `${process.env.GATSBY_MRX_API_PUBLIC_URL}/domui`;

  data!: Record<string, any>;

  directData!: Record<string, string>;

  constructor(workflow: string) {
    this.workflow = workflow;
  }

  setSwitch(sw: 'app' | 'direct') {
    this.switch = sw;
  }

  setDirectBaseUrl(url: string) {
    this.directBaseUrl = url;
  }

  setDiirectEndPoint(endpoint: string) {
    this.directEndPoint = endpoint;
  }

  setAction(action: string) {
    this.action = action;
  }

  setData(data: Record<string, any>) {
    this.data = data;
  }

  setDirectData(data: Record<string, string>) {
    this.directData = data;
  }

  getUrl() {
    return `${this.baseUrl}/${this.workflow}/${this.action}`;
  }

  async getCall() {
    if (this.switch === 'direct') {
      const directRes = await directCall(
        this.directBaseUrl,
        this.directEndPoint
      );
      return directRes;
    }
    const res = await axios.get<any>(this.getUrl(), {
      params: this.data,
    });
    return res;
  }

  async postCall() {
    if (this.switch === 'direct') {
      const directRes = await directCall(
        this.directBaseUrl,
        this.directEndPoint,
        'post',
        this.directData
      );
      return directRes;
    }
    const res = await axios.post<any>(this.getUrl(), {
      data: { ...this.data, apiMethod: 'post' },
    });
    return res;
  }

  async putCall() {
    if (this.switch === 'direct') {
      const directRes = await directCall(
        this.directBaseUrl,
        this.directEndPoint,
        'put',
        this.directData
      );
      return directRes;
    }
    const res = await axios.post<any>(this.getUrl(), {
      data: { ...this.data, apiMethod: 'put' },
    });
    return res;
  }
}
export default Workflow;
