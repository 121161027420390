import React from 'react';

import { LinkCard as RotaryToolCardNPM } from '@rotaryintl/component-landing-pages';
import { Image, RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { createLink } from '@domui-sitecore/utilities/link-helpers';

const RotaryToolCard = ({ fields: { IsFeatured, Link, Title, Description, Image: ImageField, Tag } = {} }) => (
  <RotaryToolCardNPM
    colSpan={IsFeatured?.value === true ? '2' : '1'}
    tag={Tag?.fields?.Value?.value}
    link={createLink(Link, Title)}
    title={<Text field={Title} />}
    description={<RichText field={Description} />}
    image={ImageField?.value?.src && !ImageField?.value?.src.includes('default_image.svg') && <Image field={ImageField} />}
    backgroundColor="blue"
  />
);

export default withSitecoreContext()(RotaryToolCard); 