import React from 'react';

import { L1Sublayout as L1SublayoutNPM } from '@rotaryintl/component-landing-pages';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const L1Sublayout = (props) => (
  <>
    <L1SublayoutNPM
      l1HeroPlaceholder={<Placeholder name="rotary-l1heroplaceholder" rendering={props.rendering} />}
      l1BodyPlaceholder={<Placeholder name="rotary-l1bodyplaceholder" rendering={props.rendering} />}
    />
    <Placeholder name="rotary-fullwidthsublayout" rendering={props.rendering} />
  </>
);

export default withSitecoreContext()(L1Sublayout); 