/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

const directCall = async (
  base: string,
  endpoint: string,
  method: string = 'get',
  data: Record<string, string> = {}
) => {
  let res: any;
  const url = `${base}/${endpoint}`;
  switch (method) {
    case 'get':
      res = await axios.get<any>(url);
      break;
    case 'post':
      res = await axios.post<any>(url, data);
      break;
    case 'put':
      res = await axios.put<any>(url, data);
      break;
    default:
  }
  return res;
};

export default directCall;
