import React from 'react';

import ThreeColumnImageCalloutNPM from '@rotaryintl/component-three-column-image-callout';
import { Image, RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { createLink } from '@domui-sitecore/utilities/link-helpers';

const ThreeColumnImageCallout = ({ params: { ComponentId } = {}, fields: { ImageCallouts } = {} }) => (
  <ThreeColumnImageCalloutNPM
    id={ComponentId}
    imageCallouts={ImageCallouts?.map(({ fields: { Title, Description, Image: ImageField, Link, LinkText, ShowExternalLinkIcon } = {} }) => ({
      title: <Text field={Title} />,
      description: <RichText field={Description} />,
      image: <Image field={ImageField} />,
      link: createLink(Link, LinkText, ShowExternalLinkIcon),
    }))}
  />
);

export default withSitecoreContext()(ThreeColumnImageCallout); 
