/* eslint-disable @typescript-eslint/no-explicit-any */
import Workflow from './base';

class Member extends Workflow {
  constructor() {
    super('memberwf');
    this.setDirectBaseUrl(
      'https://ri-d-usc-dom-mbr-wf-aca.gentlebay-a25ffb7b.centralus.azurecontainerapps.io'
    );
  }

  async GetAffiliationAndClubsInfo(individualId: string) {
    this.setAction('GetAffiliationAndClubsInfo');
    this.setData({
      individualId,
    });
    this.setDiirectEndPoint(
      `/Member/GetAffiliationAndClubsInfo/${individualId}`
    );
    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }
}

export default Member;
