
import React, { useEffect, useState } from 'react';

import HeaderV2NPM from '@rotaryintl/component-header-v2';
import { Image, RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import useAxios from 'axios-hooks';

import { useSetPreferredLanguageMutation } from '@components/Header/LanguageSwitcher';

import { uiLanguageToDISLanguageCode } from '@utils/languages';

import { getSiteCoreIndividualToken, MyRotary_getAllClubs, MyRotary_getAllDistrcits, MyRotary_getTokens } from '@domui-sitecore/utilities/helper-functions';
import { createLanguageLink, createLink, tokenizeLink } from '@domui-sitecore/utilities/link-helpers';


import { log } from '@hooks/logger';

import { LogLevel } from '@typings/graphql';

/** Sitecore item template ids. */
const TEMPLATE_IDS = {
  col1Folder: '803215c0-87b6-4b93-af86-1ed5494ee0f6',
  col2Folder: '7405a12c-eb56-4083-9159-aaaf8d16686d',
};

const Header = ({
  sitecoreContext,
  fields: {
    children: DataFolders,
    UtilityNavFolder,
    AccountLinksFolder,
    LanguageLinksFolder,
    SearchOptionLinksFolder,
    MainNavFolder,
    PrimaryLink,
    PrimaryLinkText,
    SecondaryLink,
    SecondaryLinkText,
    SignInLink,
    SignInLinkText,
    RegisterLink,
    RegisterLinkText,
    Logo,
    LogoText,
    MobileMenuText,
    MobileMenuBackButtonText,
  } = {},
}) => {
  const contextLanguage = sitecoreContext?.language.toLowerCase();

  const [tokens, setTokens] = useState({});
  useEffect(async () => {
    const tokensResponse = await MyRotary_getTokens(contextLanguage);
    setTokens(tokensResponse);
  }, []);

  const [allClubs, setAllClubs] = useState();
  useEffect(async () => {
    const response = await MyRotary_getAllClubs();
    setAllClubs(response);
  }, []);

  const [allDistricts, setAllDistricts] = useState();
  useEffect(async () => {
    const response = await MyRotary_getAllDistrcits();
    setAllDistricts(response);
  }, []);

  const utilityNavLinkItems = DataFolders?.find((item) => item.id === UtilityNavFolder?.id)?.fields?.children;
  const utilityNavLinks = utilityNavLinkItems?.map(({ fields: { UtilityNavLink, UtilityNavLinkText, ShowExternalLinkIcon } = {} }) =>
    createLink(UtilityNavLink, UtilityNavLinkText, ShowExternalLinkIcon)
  );

  const languageLinkItems = DataFolders?.find((item) => item.id === LanguageLinksFolder?.id)?.fields?.children;
  const [languageLinks, setLanguageLinks] = useState([]);
  useEffect(
    () =>
      setLanguageLinks(
        languageLinkItems?.map(({ fields: { LanguageLink, LanguageLinkText, Language } = {} }) =>
          createLanguageLink(
            createLink(LanguageLink, LanguageLinkText, null, {
              languageCode: LanguageLink?.value?.href?.replace('/', ''),
            })
          )
        )
      ),
    []
  );

  const selectedLanguageLinkText =
    languageLinkItems?.find(({ fields: { Language } = {} }) => Language?.name.toLowerCase() === contextLanguage)?.fields?.LanguageLinkText?.value ?? '';

  const accountLinkItems = DataFolders?.find((item) => item.id === AccountLinksFolder?.id)?.fields?.children;
  const accountLinks = accountLinkItems?.map(({ fields: { AccountLink, AccountLinkText } = {} }) =>
    tokenizeLink(createLink(AccountLink, AccountLinkText), tokens)
  );

  const searchOptionLinkItems = DataFolders?.find((item) => item.id === SearchOptionLinksFolder?.id)?.fields?.children;
  const searchOptionLinks = searchOptionLinkItems?.map(({ fields: { SearchLink, SearchLinkText } = {} }) => createLink(SearchLink, SearchLinkText));

  const mainNavItems = DataFolders?.find((item) => item.id === MainNavFolder?.id)?.fields?.children;
  const mainNav = mainNavItems?.map(
    ({
      fields: {
        MainNavTitle,
        BottomLink,
        BottomLinkText,
        ShowFeaturedCard,
        FeaturedImage,
        FeaturedDescription,
        FeaturedLink,
        FeaturedTitle,
        ShowExternalLinkIcon,
        children: MainNavColumns,
      } = {},
    }) => ({
      mainNavTitle: <Text field={MainNavTitle} />,
      columns: MainNavColumns?.map(({ templateId, fields: { children: LinkGroups } = {} }) => {
        const createSublinks = (subLinkItems) =>
          subLinkItems?.map(({ fields: { SubLink, SubLinkText, ShowExternalLinkIcon } = {} }) =>
            tokenizeLink(createLink(SubLink, SubLinkText, ShowExternalLinkIcon), tokens)
          );

        const createViewAllLink = ({ ViewAllLinkType, ViewAllLink, ViewAllLinkText }) => {
          if (!ViewAllLinkText?.value && !ViewAllLink?.value?.href) {
            return undefined;
          }
          const linkType = ViewAllLinkType?.fields?.Value?.value;          
          if (linkType === 'club' && (!allClubs || allClubs.length <= 1)) {
            return undefined;
          }         
          if (linkType === 'district' && (!allDistricts || allDistricts.length <= 1)) {
            return undefined;
          }
          return tokenizeLink(createLink(ViewAllLink, ViewAllLinkText), tokens);
        };

        if (templateId === TEMPLATE_IDS.col1Folder) {
          return {
            type: 'col1',
            linkGroups: LinkGroups?.map(({ fields: { HeaderLink, HeaderLinkText, ShowExternalLinkIcon, children: SubLinks, ...viewAllLinkFields } = {} }) => ({
              headerLink: createLink(HeaderLink, HeaderLinkText, ShowExternalLinkIcon),
              subLinks: createSublinks(SubLinks),
              viewAllLink: createViewAllLink(viewAllLinkFields),
            })),
          };
        } if (templateId === TEMPLATE_IDS.col2Folder) {
          return {
            type: 'col2',
            linkGroups: LinkGroups?.map(({ fields: { HeaderLink, HeaderLinkText, ShowExternalLinkIcon, Column1, Column2, ...viewAllLinkFields } = {} }) => ({
              headerLink: createLink(HeaderLink, HeaderLinkText, ShowExternalLinkIcon),
              col1: createSublinks(Column1),
              col2: createSublinks(Column2),
              viewAllLink: createViewAllLink(viewAllLinkFields),
            })),
          };
        }
      }),
      showFeaturedCard: ShowFeaturedCard?.value,
      featuredImage: <Image field={FeaturedImage} />,
      featuredDescription: <RichText field={FeaturedDescription} />,
      featuredLink: tokenizeLink(createLink(FeaturedLink, FeaturedTitle, ShowExternalLinkIcon), tokens),
      bottomLink: createLink(BottomLink, BottomLinkText),
    })
  );

  // Language Selection Logic
  const [setPreferredLanguage] = useSetPreferredLanguageMutation();
  const [, setDrupalLanguage] = useAxios(
    {
      withCredentials: true,
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const onLanguageSelected = async (selectedLanguageLink) => {
    const selectedLanguage = selectedLanguageLink?.linkData?.languageCode;
    const targetURL = selectedLanguageLink?.linkUrl ?? '';

    const individual = getSiteCoreIndividualToken();
    if (individual.individualId && selectedLanguage) {
      try {
        const preferredLanguage = uiLanguageToDISLanguageCode[selectedLanguage];
        await setPreferredLanguage({
          variables: {
            individualId: individual.individualId,
            preferredLanguage,
          },
        }); 

        setDrupalLanguage({
          url: `${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/user/language/${selectedLanguage}`,
        });

        window.location.href = window.location.origin + targetURL;
      } catch (e) {
        log({
          level: LogLevel.Error,
          message: `Error while chaning language. Error as String: ${e.toString()}`,
        });
      }
    } else {
      window.location.href = window.location.origin + targetURL;
    }
  }

  return (
    <HeaderV2NPM
      utilityNavLinks={utilityNavLinks}
      languageLinks={languageLinks}
      selectedLanguage={selectedLanguageLinkText}
      onLanguageSelect={onLanguageSelected}
      signInLink={SignInLinkText?.value ? createLink(SignInLink, SignInLinkText) : null}
      registerLink={RegisterLinkText?.value ? createLink(RegisterLink, RegisterLinkText) : null}
      accountLinks={accountLinks}
      accountLabel={<Text field={AccountLinksFolder?.fields?.AccountLabel} />}
      mobileMenuText={MobileMenuText?.value}
      mobileMenuBackButtonText={MobileMenuBackButtonText?.value}
      logo={<Image field={Logo} />}
      logoText={<Text field={LogoText} />}
      logoLink={{ linkUrl: '/', linkText: '', linkTarget: '_self' }}
      primaryLink={createLink(PrimaryLink, PrimaryLinkText)}
      secondaryLink={createLink(SecondaryLink, SecondaryLinkText)}
      searchOptionLinks={searchOptionLinks}
      mainNav={mainNav}
    />
  );
};

export default withSitecoreContext()(Header);
