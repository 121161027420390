/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  FC,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ApolloClient, InMemoryCache, useApolloClient } from '@apollo/client';
import { newIORef } from 'fp-ts/lib/IORef';
import * as O from 'fp-ts/Option';
import { useAsyncResource } from 'use-async-resource';

import { useSitecoreLayout } from './useSitecoreLayout';
import { useSiteCoreUserType } from './useSiteCoreUserType';
import { DOMUI_CLUB_DISTRICT_DATA } from '../sitecore/constants';
import Individual from '../utils/workflow/individual';
import Member from '../utils/workflow/member';

import {
  AppConfig,
  AppConfigContextType,
  defaultConfig,
} from '@domain/appConfig';
import { User } from '@domain/auth';

import { login, logout, useUserTrackingEvent } from '@use-cases/auth';
import { useNavigateLanguage } from '@use-cases/language-negotiation';
import { useErrorHandling } from '@use-cases/notifications';

import { getAppConfig } from '@repositories/appConfig';
import { useOktaWidget } from '@repositories/auth/hooks';

import Loading from '@domui-components/Loading';
import { getAllDayLabel } from '@domui-sitecore/sitecoreTranslations';

import { useTranslation } from '@external/react-i18next';
import ErrorBoundary from '@hooks/error-boundary';

const AppConfigContext = createContext<AppConfigContextType>({
  ...defaultConfig,
  oktaWidget: {
    usernameUsedForLogin: newIORef<O.Option<string>>(O.none)(),
    clearTokens: () => {},
    setTokens: () => {},
  },
  setUser: () => {},
  clearUser: () => {},
  login,
  logout,
  refetch: () => {},
  activateMaintenanceMode: () => {},
  isProcessingLogin: false,
  setProcessingLogin: () => {},
  sitecoreContent: {},
});

const SuspendedWrapper: FC<{
  configReader: Function;
  fetchNewAppConfig: Function;
  language: string;
  delocalizedPathIn: string;
}> = ({
  configReader,
  fetchNewAppConfig,
  language: pageContextLanguage,
  delocalizedPathIn,
  children,
}) => {
  const { t } = useTranslation();

  const data: AppConfig = configReader();
  const { user: fetchedUser = { login: '', isLoggedIn: false } } = data;
  const oktaWidget = useOktaWidget(fetchedUser);
  const [appConfig, setConfig] = useState<AppConfig>({
    ...data,
    user: fetchedUser,
  });
  const [isProcessingLogin, setProcessingLogin] = useState<boolean>(false);

  const {
    isMaintenanceMode,
    language,
    delocalizedPath,
    userAnalytics,
    localizedPath,
  } = appConfig;

  const user = data.user || appConfig.user;

  const userType = useSiteCoreUserType(user, isProcessingLogin);
  const sitecoreContent = useSitecoreLayout(userType, '/', language);
  const [isLoading, setIsLoading] = useState(true);
  const districtLabel = t('search.members.filters-district-label', 'District');
  const weekDayLabels = getAllDayLabel(t);
  const [error, setError] = useState<any>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user?.nfKey) {
          const wfService = new Individual();
          const memberWfService = new Member();

          const domId = await wfService.getDominoId(user.nfKey);
          const [individualInfo, getClubDistrictData] = await Promise.all([
            wfService.getDominoIndividual(domId),
            memberWfService.GetAffiliationAndClubsInfo(domId),
          ]);
          if (!(individualInfo || getClubDistrictData)) {
            setError({
              message: 'Error while retrieving the data',
            });
          }

          window.localStorage.setItem(
            DOMUI_CLUB_DISTRICT_DATA,
            JSON.stringify({
              individualInfo,
              getClubDistrictData,
              districtLabel,
              weekDayLabels,
            })
          );
        }
      } catch (error) {
        // Handle the error
        setError({
          message: 'Error while retrive the  info',
        });
      } finally {
        setIsLoading(false); // Set loading to false when done
      }
    };

    fetchData();
  }, [user]);

  useErrorHandling(error?.message, !!error, 'fetch.error');

  const activateMaintenanceMode = () => {
    setConfig({ ...appConfig, isMaintenanceMode: false });
  };

  const refetch = () => {
    fetchNewAppConfig();
  };

  const setUser = (newUser: User) => {
    setConfig({ ...appConfig, user: { ...newUser } });
  };

  const clearUser = () => {
    setConfig({
      ...appConfig,
      user: {
        login: '',
        isLoggedIn: false,
      } as User,
    });
  };

  // trigger analytics on user change
  useUserTrackingEvent(appConfig, isProcessingLogin);
  // navigate to negotiated language
  useNavigateLanguage(appConfig, pageContextLanguage);

  useEffect(() => {
    if (
      (pageContextLanguage && data.language !== pageContextLanguage) ||
      delocalizedPath !== delocalizedPathIn
    ) {
      setConfig({
        ...data,
        user,
        delocalizedPath: delocalizedPathIn,
      });
    }
  }, [pageContextLanguage, delocalizedPathIn, data.language]);
  if (isLoading) {
    return <Loading />; // Show a loading indicator while fetching
  }

  return (
    <AppConfigContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user,
        isMaintenanceMode,
        isProcessingLogin,
        language,
        delocalizedPath,
        userAnalytics,
        localizedPath,
        login: (setCurrentPathAsDestination = false) => {
          return login(setCurrentPathAsDestination, language);
        },
        logout: (destination?: string) => {
          /* PECORE-42 change
          oktaWidget.clearTokens();
          */
          return logout(destination);
        },
        refetch,
        activateMaintenanceMode,
        setUser,
        clearUser,
        setProcessingLogin,
        oktaWidget,
        sitecoreContent,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig: () => AppConfigContextType = () => {
  return useContext(AppConfigContext);
};

const AppConfigProvider: FC<{ language: string; path: string }> = ({
  language: pageContextLanguage,
  path: delocalizedPathIn,
  children,
}) => {
  defaultConfig.delocalizedPath = delocalizedPathIn;

  const client = useApolloClient();
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();
  const fetchConfig = getAppConfig(
    client as ApolloClient<InMemoryCache>,
    currentLanguage,
    pageContextLanguage,
    delocalizedPathIn
  );

  const [fetchedAppConfig, fetchNewAppConfig] = useAsyncResource(fetchConfig);

  useMemo(() => {
    fetchNewAppConfig();
  }, [pageContextLanguage]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <SuspendedWrapper
          configReader={fetchedAppConfig}
          language={pageContextLanguage}
          delocalizedPathIn={delocalizedPathIn}
          fetchNewAppConfig={fetchNewAppConfig}
        >
          {children}
        </SuspendedWrapper>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AppConfigProvider;
