import React from 'react';

import { L1Hero as L1HeroNPM } from '@rotaryintl/component-landing-pages';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const L1Hero = ({ fields: { Title, Description, BackgroundImageDesktop, BackgroundImageMobile } = {} }) => (
  <L1HeroNPM
    title={<Text field={Title} />}
    description={<RichText field={Description} />}
    backgroundImageDesktop={BackgroundImageDesktop?.value?.src}
    backgroundImageMobile={BackgroundImageMobile?.value?.src}
    backgroundImageAlt={BackgroundImageDesktop?.value?.alt}
  />
);

export default withSitecoreContext()(L1Hero); 