import React from 'react';

import { Announcements as AnnouncementsNPM } from '@rotaryintl/component-two-column-grey-wrapper';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { GetFormattedDate } from '@domui-sitecore/utilities/date-helper-functions';
import { createLink } from '@domui-sitecore/utilities/link-helpers';

const Announcements = ({ sitecoreContext, params: { ComponentId } = {}, fields: { AnnouncementItems, Link, LinkText, ShowExternalLinkIcon, Title } = {} }) => (
  <AnnouncementsNPM
    id={ComponentId}
    title={<Text field={Title} />}
    link={createLink(Link, LinkText, ShowExternalLinkIcon)}
    announcements={AnnouncementItems?.map(({ fields: { Description, Date: DateField } = {} }) => ({
      description: <RichText field={Description} />,
      date: GetFormattedDate(sitecoreContext?.language?.toLowerCase(), new Date(DateField?.value)),
    }))}
  />
);

export default withSitecoreContext()(Announcements); 
