import React from 'react';

import { Calendar as CalendarNPM } from '@rotaryintl/component-two-column-grey-wrapper';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { GetFormattedDate } from '@domui-sitecore/utilities/date-helper-functions';
import { createLink } from '@domui-sitecore/utilities/link-helpers';

const Calendar = ({
  sitecoreContext,
  params: { ComponentId } = {},
  fields: { Title, Link, LinkText, ShowExternalLinkIcon, children: CalendarEventItems } = {},
}) => {
  let yesterday = new Date();
  yesterday = yesterday.setDate(yesterday.getDate() - 1);

  const currentCalendarEventItems = CalendarEventItems?.filter((item) => item && new Date(item?.fields?.Date?.value) > yesterday).sort(
    (a, b) => new Date(a?.fields?.Date?.value) - new Date(b?.fields?.Date?.value)
  );

  if (currentCalendarEventItems && currentCalendarEventItems.length <= 0) {
    return <></>;
  }

  return (
    <CalendarNPM
      id={ComponentId}
      title={<Text field={Title} />}
      link={createLink(Link, LinkText, ShowExternalLinkIcon)}
      calendarEvents={currentCalendarEventItems?.map(({ fields: { Description, Date: DateField } = {} }) => ({
        description: <RichText field={Description} />,
        date: GetFormattedDate(sitecoreContext?.language?.toLowerCase(), new Date(DateField?.value)),
      }))}
    />
  );
};

export default withSitecoreContext()(Calendar); 
